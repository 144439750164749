// src/Header.js
import React, { useEffect, useState } from "react";
import "./Footer.css"; // Optional: import CSS for styling
import { GiSoundWaves } from "react-icons/gi";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewRelease,
  getRecentReleaseFooter,
  resetNewRelease,
  resetRecentReleaseFooter,
} from "../../Store/actions";

const Footer = ({ links = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recentRelease, setrecentRelease] = useState([]);
  const [newRelease, setnewRelease] = useState([]);

  const {
    artistData,
    recentReleaseData,
    recentReleaseCode,
    newReleaseData,
    newReleaseCode,
    recentReleaseFooterData,
    recentReleaseFooterCode,
  } = useSelector((state) => ({
    artistData: state.artistReducer.artistData,
    recentReleaseData: state.releaseReducer.recentReleaseData,
    recentReleaseCode: state.releaseReducer.recentReleaseCode,
    newReleaseData: state.releaseReducer.newReleaseData,
    newReleaseCode: state.releaseReducer.newReleaseCode,
    recentReleaseFooterData: state.releaseReducer.recentReleaseFooterData,
    recentReleaseFooterCode: state.releaseReducer.recentReleaseFooterCode,
  }));

  console.log("recentReleaseFooterData=========", recentReleaseFooterData);
  console.log("recentReleaseFooterCode=========", recentReleaseFooterCode);

  useEffect(() => {
    if (recentReleaseFooterCode == 200) {
      setrecentRelease(recentReleaseFooterData);
      // dispatch(resetRecentReleaseFooter());
    }
  }, [recentReleaseFooterCode]);

  useEffect(() => {
    if (newReleaseCode == 200) {
      setnewRelease(newReleaseData);
      dispatch(resetNewRelease());
    }
  }, [newReleaseCode]);

  const handlereleaseClick = (gid) => {
    navigate(`/release/${gid}`, {
      state: {
        release_gid: gid,
      },
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getNewRelease());
      dispatch(getRecentReleaseFooter());
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="footerDiv">
          <div className="upperDiv">
            <div className="menu">
              <p className="menuHeading">Random releases</p>
              <div>
                {recentRelease.map((i, k) => (
                  <p
                    className="menuText linkHover"
                    onClick={() => handlereleaseClick(i.gid)}
                    key={k}
                  >
                    {i.name.slice(0, 30)}
                  </p>
                ))}
              </div>
            </div>
            <div className="menu">
              <p className="menuHeading">Suggested releases</p>
              <div>
                {newRelease.map((i, k) => (
                  <p
                    className="menuText linkHover"
                    onClick={() => handlereleaseClick(i.gid)}
                    key={k}
                  >
                    {i.name.slice(0, 30)}
                  </p>
                ))}
              </div>
            </div>
            <div className="menu">
              <p className="menuHeading">Company</p>
              <div>
                <p
                  className="menuText"
                  onClick={() => {
                    navigate("/aboutus");
                  }}
                >
                  About Us
                </p>
                <p
                  className="menuText"
                  onClick={() => {
                    navigate("/contactus");
                  }}
                >
                  Contact Us
                </p>
                <p
                  className="menuText"
                  onClick={() => {
                    navigate("/privacypolicy");
                  }}
                >
                  Privacy policy
                </p>
                {/* <p
                  className="menuText"
                  onClick={() => {
                    navigate("/help");
                  }}
                >
                  Help & Support
                </p> */}
              </div>
            </div>
            <div className="menu">
              <p className="menuHeading">Social links</p>
              <div className="socialDiv">
                <FaFacebook color="#5B7EF0" size={"30px"} />
                <AiFillInstagram color="#5B7EF0" size={"40px"} />
                <FaTwitter color="#5B7EF0" size={"30px"} />
              </div>
            </div>
          </div>
          <div className="lowerDiv">
            <p className="termsText">
              &copy; 2024 SoundWave Limited All rights reserved.
            </p>
            <div className="header-logo">
              <GiSoundWaves color="#5B7EF0" size={"45px"} />
              <h2 className="headerHeading">
                Sound<label className="headerHeading1">Wave</label>
              </h2>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
