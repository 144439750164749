import React, { useEffect, useMemo, useState } from "react";
import { TbUserPentagon } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Button, Col, Input, Row } from "reactstrap";
import { Grid, _ } from "gridjs-react";
import "./ReleaseGroup.css";
import { MdLibraryMusic } from "react-icons/md";
import {
  getRelease,
  getReleaseGroup,
  getReleaseGroupRelease,
  getReleaseGroupTag,
  getReleaseGroupTrack,
  getReleaseGroupUrl,
  resetRelease,
  resetReleaseGroup,
  resetgetReleaseGroupRelease,
  resetgetReleaseGroupTag,
} from "../../Store/actions";
import { useNavigate, useParams } from "react-router-dom";

const ReleaseGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { gid } = useParams();

  console.log("gid of artist", gid);

  const [hasError, setHasError] = useState(false);

  // Helper to generate random colors
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        release_group_gid: gid,
      };

      let reqdata1 = {
        release_group_gid: gid,
        page: 1,
      };

      dispatch(getReleaseGroup(reqdata));
      dispatch(getReleaseGroupTag(reqdata));
      dispatch(getReleaseGroupUrl(reqdata));
      dispatch(getReleaseGroupRelease(reqdata1));

      const color1 = getRandomColor();
      const color2 = getRandomColor();
      setrandomColor(`linear-gradient(135deg, ${color1}, ${color2})`);
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]); // Empty dependency array means it should only run once

  const {
    releaseGroupData,
    releaseGroupUrlData,
    releaseGroupTagData,
    releaseGroupReleaseData,

    releaseCode,
    releaseGroupCode,
    releaseGroupUrlCode,
    releaseGroupReleaseCode,
    releaseGroupTagCode,
    releaseGroupReleaseCurrentPage,
    releaseGroupReleaseTotalPage,
  } = useSelector((state) => ({
    releaseGroupData: state.releaseReducer.releaseGroupData,
    releaseGroupUrlData: state.releaseReducer.releaseGroupUrlData,
    releaseGroupTagData: state.releaseReducer.releaseGroupTagData,
    releaseGroupReleaseData: state.releaseReducer.releaseGroupReleaseData,

    releaseGroupReleaseCurrentPage:
      state.releaseReducer.releaseGroupReleaseCurrentPage,
    releaseGroupReleaseTotalPage:
      state.releaseReducer.releaseGroupReleaseTotalPage,

    releaseCode: state.releaseReducer.releaseCode,
    releaseGroupCode: state.releaseReducer.releaseGroupCode,
    releaseGroupUrlCode: state.releaseReducer.releaseGroupUrlCode,
    releaseGroupReleaseCode: state.releaseReducer.releaseGroupReleaseCode,
    releaseGroupTagCode: state.releaseReducer.releaseGroupTagCode,
  }));

  const [releaseGroupInfo, setreleaseGroupInfo] = useState({
    release_group_id: "",
    release_group_gid: "",
    name: "",
    type: "",
    artist_credit_id: "",
    cover_art_url: "",
    artist_credit_names: [],
  });
  const [releaseGroupTag, setreleaseGroupTag] = useState([]);
  const [releaseGroupRelease, setreleaseGroupRelease] = useState([]);

  useEffect(() => {
    if (releaseGroupTagCode == 200) {
      const groupedData = releaseGroupTagData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.type]) {
          result[item.type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.type].push(item);
        return result;
      }, {});

      console.log(groupedData);
      setreleaseGroupTag(groupedData);

      dispatch(resetgetReleaseGroupTag());
    }
  }, [releaseGroupTagCode]);

  useEffect(() => {
    if (releaseGroupCode == 200) {
      setreleaseGroupInfo(releaseGroupData);
      dispatch(resetReleaseGroup());
    }
  }, [releaseGroupCode]);

  useEffect(() => {
    if (releaseGroupReleaseCode == 200) {
      setreleaseGroupRelease(releaseGroupReleaseData);
      dispatch(resetgetReleaseGroupRelease());
    }
  }, [releaseGroupReleaseCode]);

  const handleReleaseGroupClick = (cell, row, id) => {
    let reqdata = {
      release_gid: id,
    };

    navigate(`/release/${id}`, {
      state: {
        release_gid: id,
      },
    });
  };

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  const BaseExample = useMemo(() => {
    return (
      <Grid
        data={releaseGroupRelease}
        columns={[
          {
            id: "gid",
            name: "gid",
            hidden: true,
          },

          {
            id: "artist_credit_names",
            name: "artist_credit_names",
            hidden: true,
          },
          {
            id: "country_name",
            name: "country_name",
            hidden: true,
          },
          {
            id: "date_year",
            name: "date_year",
            hidden: true,
          },
          {
            id: "date_month",
            name: "date_month",
            hidden: true,
          },
          {
            id: "date_day",
            name: "date_day",
            hidden: true,
          },
          {
            id: "name",
            name: "Release",
            formatter: (cell, row) =>
              _(
                <div>
                  <p
                    className="linkHover"
                    style={{ cursor: "pointer", margin: 0 }}
                    onClick={() =>
                      handleReleaseGroupClick(cell, row, row.cells[0]["data"])
                    }
                  >
                    {cell}
                  </p>
                </div>
              ),
            width: "auto",
          },
          {
            id: "name",
            name: "Artist",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ cursor: "pointer", margin: 0 }}>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <label
                          className="linkHover"
                          style={{ cursor: "pointer", margin: 0 }}
                          key={k}
                          onClick={() => handleArtistNameClick(i.artist_gid)}
                        >
                          {i.name}
                          {k != row.cells[1]["data"].length - 1 && ", "}
                        </label>
                      </>
                    ))}
                  </p>
                </div>
              ),
            width: "auto",
          },
          {
            id: "format",
            name: "Format",
            width: "auto",
          },
          {
            id: "track_count",
            name: "Tracks",
            width: "auto",
          },
          {
            id: "Country/Date",
            name: "Country/Date",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ margin: 0 }}>
                    {row.cells[2]["data"]} {row.cells[3]["data"]}-
                    {row.cells[4]["data"]}-{row.cells[5]["data"]}
                  </p>
                </div>
              ),
            width: "auto",
          },
          {
            id: "catalog_number",
            name: "Catalog",
            width: "auto",
          },
          {
            id: "barcode",
            name: "Barcode",
            width: "auto",
          },
        ]}
        // search={true}
        autoWidth={true}
      />
    );
  }, [releaseGroupRelease]);

  const [pagecount, setpagecount] = useState(releaseGroupReleaseCurrentPage);
  const [inputcount, setinputcount] = useState(1);
  const [pagesizedata, setpagesizedata] = useState(0);

  useEffect(() => {
    setpagecount(releaseGroupReleaseCurrentPage);
  }, [releaseGroupReleaseCurrentPage]);

  const previousPage = () => {
    if (pagecount > 1) {
      setpagesizedata(1);
      setpagecount(pagecount - 1);

      let reqdata1 = {
        release_group_gid: gid,
        page: 1,
      };
      dispatch(getReleaseGroupRelease(reqdata1));
    }
  };

  const nextPage = () => {
    if (pagecount < releaseGroupReleaseTotalPage) {
      setpagesizedata(1);
      setpagecount(pagecount + 1);

      let reqdata1 = {
        release_group_gid: gid,
        page: 1,
      };
      dispatch(getReleaseGroupRelease(reqdata1));
    }
  };

  const onChangeInInput = (event) => {
    setinputcount(event);
  };

  const onBlurInput = (event) => {
    if (event > 0 && event <= releaseGroupReleaseTotalPage) {
      let reqdata1 = {
        release_group_gid: gid,
        page: 1,
      };
      dispatch(getReleaseGroupRelease(reqdata1));

      setpagecount(parseInt(event));
    }
  };

  const [randomColor, setrandomColor] = useState();

  console.log("hasError-=-=-=-=-=-=-=-=-=-=-=-=", hasError);
  console.log("hasError-=-=-=-=-=-=-=-=-=-=-=-=", hasError);

  return (
    <Row>
      <Col md="2">
        <div className="leftMainDiv">
          <div>
            <p className="infoHeading">Release group information</p>
            <p className="infoText">
              <strong>Sort name:</strong> {releaseGroupInfo.name}
            </p>
            <p className="infoText">
              <strong>Type:</strong> {releaseGroupInfo.type}
            </p>
          </div>
          <div>
            <p className="infoHeading">Tags</p>
            {Object.entries(releaseGroupTag).map(([groupName, items]) => (
              <div key={groupName}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Col>

      <Col md="10" style={{ borderLeft: "1px solid #ccc" }}>
        <div className="nameDiv">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: randomColor,
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              cursor: "pointer",
              width: "150px",
              height: "150px",
              borderRadius: "5px",
            }}
          >
            {!hasError ? (
              <img
                src={
                  releaseGroupInfo &&
                  releaseGroupInfo.cover_art_url != "" &&
                  releaseGroupInfo.cover_art_url
                }
                alt={releaseGroupInfo.name}
                onError={() => setHasError(true)}
                style={{ width: "150px", height: "150px", borderRadius: "5px" }}
              />
            ) : (
              <span style={{ fontSize: "20px", fontWeight: "600" }}>
                {releaseGroupInfo.name?.slice(0, 20)}
              </span>
            )}
          </div>
          {/* <img src={releaseGroupInfo.cover_art_url} className="coverImage" /> */}
          <div>
            <strong className="artistName">{releaseGroupInfo.name}</strong>
            <p style={{ margin: 0 }}>
              ~Release group by{" "}
              {releaseGroupInfo &&
                releaseGroupInfo.artist_credit_names &&
                releaseGroupInfo.artist_credit_names.map((i, k) => (
                  <>
                    <span
                      style={{ margin: 0, color: "#5B7EF0", cursor: "pointer" }}
                      onClick={() => handleArtistNameClick(i.artist_gid)}
                    >
                      {i.name}{" "}
                    </span>
                    {i.join_phrase}{" "}
                  </>
                ))}
            </p>
          </div>
        </div>
        <Tabs
          style={{ marginTop: "30px" }}
          defaultIndex={0}
          //onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Tags</Tab>
          </TabList>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Album
              </p>
              {BaseExample}
              <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={previousPage}
                      disabled={pagecount == 1 ? true : false}
                    >
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Page{" "}
                  <strong>
                    {pagecount} of {releaseGroupReleaseTotalPage}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    // max={orders.last_page}
                    value={inputcount}
                    defaultValue={1}
                    onChange={(e) => onChangeInInput(e.target.value)}
                    onBlur={(e) => onBlurInput(e.target.value)}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={nextPage}
                      disabled={
                        pagecount == releaseGroupReleaseTotalPage ? true : false
                      }
                    >
                      {">"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <p className="infoHeading">Tags</p>
              {Object.entries(releaseGroupTag).map(([groupName, items]) => (
                <div key={groupName}>
                  <strong>{groupName}</strong>
                  {items.map((item) => (
                    <p style={{ margin: 0 }} key={item.tag_id}>
                      {item.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default ReleaseGroup;
