import React, { createContext, useState, useContext } from "react";

const PlayerContext = createContext();

export const usePlayer = () => useContext(PlayerContext);

export const PlayerProvider = ({ children }) => {
  const [videoId, setVideoId] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);

  const playVideo = (id) => {
    setVideoId(id);
    setIsMinimized(false);
    setIsDraggable(true);
  };

  const minimizePlayer = () => setIsMinimized(true);
  const closePlayer = () => setVideoId(null);
  const setFullscreenPlayer = () => setIsDraggable(false);

  return (
    <PlayerContext.Provider
      value={{
        videoId,
        isMinimized,
        isDraggable,
        playVideo,
        minimizePlayer,
        closePlayer,
        setFullscreenPlayer,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
