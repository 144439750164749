import React, { useEffect, useMemo, useState } from "react";
import CommonNewGrid from "../../Component/CommonNewGrid";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import {
  getArtist,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  getSearch,
  resetArtist,
  resetSearch,
} from "../../Store/actions";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const SearchArtist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { searchtype, searchquery } = useParams();

  console.log("searchtype of artist", searchtype);
  console.log("searchquery of artist", searchquery);

  const {
    searchData,
    searchCode,
    artistCode,
    searchType,
    searchRecords,
    searchPages,
    searchCurrentPages,
  } = useSelector((state) => ({
    searchData: state.searchReducer.searchData,
    searchCode: state.searchReducer.searchCode,
    searchType: state.searchReducer.searchType,
    artistCode: state.artistReducer.artistCode,
    searchRecords: state.searchReducer.searchRecords,
    searchPages: state.searchReducer.searchPages,
    searchCurrentPages: state.searchReducer.searchCurrentPages,
  }));

  const [artistSearchData, setartistSearchData] = useState([]);

  console.log("artistSearchData==========", artistSearchData);

  useEffect(() => {
    if (Array.isArray(searchData) && searchType == "artist") {
      setartistSearchData(searchData);
      dispatch(resetSearch());
    }
  }, [searchData]);

  const [artistName, setartistName] = useState(null);
  const [artistType, setartistType] = useState(null);
  const [artistId, setartistId] = useState(null);

  const handleArtistClick = (cell, row, id, type) => {
    navigate(`/artist/${id}`, {
      state: {
        artistName: cell,
        artistType: type,
        artistId: id,
      },
    });

    setartistName(cell);
    setartistType(type);
    setartistId(id);
  };

  const BaseExample = useMemo(() => {
    return (
      <React.Fragment>
        <Grid
          data={artistSearchData}
          columns={[
            {
              id: "artist_gid",
              name: "id",
              hidden: true,
            },
            {
              id: "comment",
              name: "comment",
              hidden: true,
            },
            {
              id: "artist_name",
              name: "Name",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      style={{ cursor: "pointer", margin: 0 }}
                      className="linkHover"
                      onClick={() =>
                        handleArtistClick(
                          cell,
                          row,
                          row.cells[0]["data"],
                          row.cells[3]["data"]
                        )
                      }
                    >
                      {cell}{" "}
                      {row.cells[1]["data"] != "" && (
                        <span style={{ color: "grey", fontSize: "12px" }}>
                          ({row.cells[1]["data"]})
                        </span>
                      )}
                    </p>
                  </div>
                ),
              width: "auto",
            },
            {
              id: "artist_short_name",
              name: "Sort Name",
              width: "auto",
            },
            {
              id: "artist_type",
              name: "Type",
              width: "auto",
            },
            {
              id: "gender",
              name: "Gender",
              width: "auto",
            },
            {
              id: "area_name",
              name: "Area",
              width: "auto",
            },
            {
              id: "begin",
              name: "Begin",
              width: "auto",
            },
            {
              id: "area_name",
              name: "Begin Area",
              width: "auto",
            },
            {
              id: "enda",
              name: "End",
              width: "auto",
            },
            {
              id: "end_area",
              name: "End Area",
              width: "auto",
            },
          ]}
          // search={true}
          // sort={true}
          // height={height}
          // style={{ height: { height }, overflowY: "scroll" }}
          // pagination={{ enabled: true, limit: 25 }}
        />
      </React.Fragment>
    );
  }, [artistSearchData]);

  const [pagecount, setpagecount] = useState(searchCurrentPages);
  const [inputcount, setinputcount] = useState(1);
  const [pagesizedata, setpagesizedata] = useState(0);

  useEffect(() => {
    setpagecount(searchCurrentPages);
  }, [searchCurrentPages]);

  const previousPage = () => {
    if (pagecount > 1) {
      setpagesizedata(1);
      setpagecount(pagecount - 1);

      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: pagecount - 1,
      };
      dispatch(getSearch(reqdata));
    }
  };

  const nextPage = () => {
    if (pagecount < searchPages) {
      setpagesizedata(1);
      setpagecount(pagecount + 1);

      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: pagecount + 1,
      };
      dispatch(getSearch(reqdata));
    }
  };

  const onChangeInInput = (event) => {
    setinputcount(event);
  };

  const onBlurInput = (event) => {
    if (event > 0 && event <= searchPages) {
      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: event,
      };
      dispatch(getSearch(reqdata));

      setpagecount(parseInt(event));
    }
  };

  return (
    <>
      {/* <div>
        <h3>Search Result</h3>
      </div> */}
      <Card>
        <CardHeader>
          <h3>Search Result</h3>
        </CardHeader>
        <CardBody>{BaseExample}</CardBody>
        <CardFooter>
          <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
            {/* <Col className="col-md-auto">
              <Select
                options={dropdownmenu}
                onChange={(e) => handledropdown(e)}
              />
            </Col> */}
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={pagecount == 1 ? true : false}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pagecount} of {searchPages}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                // max={orders.last_page}
                value={inputcount}
                defaultValue={1}
                onChange={(e) => onChangeInInput(e.target.value)}
                onBlur={(e) => onBlurInput(e.target.value)}
              />
            </Col>
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={pagecount == searchPages ? true : false}
                >
                  {">"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default SearchArtist;
