import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import "./Artist.css";
import { CiStar } from "react-icons/ci";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaInstagram, FaRegStar, FaYoutube } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { TbUserPentagon, TbWorld } from "react-icons/tb";
import { useParams } from "react-router-dom";
import {
  getArtist,
  getArtistRelationship,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  getReleaseGroup,
  getReleaseGroupRelease,
  getReleaseGroupTag,
  getReleaseGroupUrl,
  resetArtist,
  resetGetArtistRelease,
  resetGetArtistReleaseGroup,
  resetReleaseGroup,
  resetgetArtistRelationship,
  resetgetArtistTag,
  resetgetArtistUrl,
} from "../../Store/actions";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { SiDiscogs } from "react-icons/si";
import homeSweetHome from "./../../assets/officialhomepage.png";
import { FaUser } from "react-icons/fa";

const Artist = ({ props }) => {
  console.log(props);

  let { gid } = useParams();

  console.log("gid of artist", gid);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: 1,
      };
      dispatch(getArtist(reqdata));
      dispatch(getArtistTag(reqdata));
      dispatch(getArtistUrl(reqdata));
      dispatch(getArtistReleaseGroup(reqdata));
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]); // Empty dependency array means it should only run once

  const typeToIcon = {
    Instagram: <FaInstagram style={{ marginRight: "8px" }} />,
    YouTube: <FaYoutube style={{ marginRight: "8px" }} />,
    Discogs: <SiDiscogs style={{ marginRight: "8px" }} />,
    // Add other types and icons here as needed
  };

  const {
    artistCode,
    artistData,
    artistTagData,
    artistUrlData,

    artistReleaseGroupData,
    artistReleaseCurrentPage,
    artistReleaseTotalPage,

    artistReleaseData,
    artistRelationShipData,
    artistReleaseDataCurrentPage,
    artistReleaseDataTotalPage,

    artistReleaseCode,
    artistReleaseGroupCode,
    artistTagCode,
    artistRelationShipCode,
    artistUrlCode,
  } = useSelector((state) => ({
    artistData: state.artistReducer.artistData,
    artistTagData: state.artistReducer.artistTagData,
    artistUrlData: state.artistReducer.artistUrlData,

    artistReleaseGroupData: state.artistReducer.artistReleaseGroupData,
    artistReleaseCurrentPage: state.artistReducer.artistReleaseCurrentPage,
    artistReleaseTotalPage: state.artistReducer.artistReleaseTotalPage,

    artistReleaseData: state.artistReducer.artistReleaseData,
    artistReleaseDataCurrentPage:
      state.artistReducer.artistReleaseDataCurrentPage,
    artistReleaseDataTotalPage: state.artistReducer.artistReleaseDataTotalPage,

    artistRelationShipData: state.artistReducer.artistRelationShipData,

    artistCode: state.artistReducer.artistCode,
    artistReleaseCode: state.artistReducer.artistReleaseCode,
    artistReleaseGroupCode: state.artistReducer.artistReleaseGroupCode,
    artistTagCode: state.artistReducer.artistTagCode,
    artistRelationShipCode: state.artistReducer.artistRelationShipCode,
    artistUrlCode: state.artistReducer.artistUrlCode,
  }));

  const [artistInfo, setartistInfo] = useState({
    artist_id: "",
    artist_gid: "",
    name: "",
    sort_name: "",
    artist_type: "",
    gender: "",
    comment: "",
    born: "",
    end: "",
    area: "",
    born_in: "",
    end_area: null,
  });
  const [artistTag, setartistTag] = useState([]);
  const [artistUrl, setartistUrl] = useState([]);
  const [artistUrlRelation, setartistUrlRelation] = useState([]);
  const [artistReleaseGroup, setartistReleaseGroup] = useState([]);
  const [artistRelease, setartistRelease] = useState([]);
  const [artistRelationship, setartistRelationship] = useState([]);

  console.log("artistRelationShipData=========", artistRelationShipData);

  useEffect(() => {
    if (artistCode == 200) {
      setartistInfo(artistData);
      dispatch(resetArtist());
    }
  }, [artistData]);

  useEffect(() => {
    if (artistRelationShipCode == 200) {
      // setartistRelationship(artistRelationShipData);

      const groupedData = artistRelationShipData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.attribute_count]) {
          result[item.attribute_count] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.attribute_count].push(item);
        return result;
      }, {});

      console.log(groupedData);
      // setreleaseGroupTag(groupedData);

      setartistRelationship(groupedData);
      dispatch(resetgetArtistRelationship());
    }
  }, [artistRelationShipData]);

  useEffect(() => {
    if (artistTagCode == 200) {
      const groupedData = artistTagData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.type]) {
          result[item.type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.type].push(item);
        return result;
      }, {});

      setartistTag(groupedData);
      dispatch(resetgetArtistTag());
    }
  }, [artistTagData]);

  useEffect(() => {
    if (artistUrlCode == 200) {
      setartistUrl(artistUrlData);

      const groupedData = artistUrlData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.link_type]) {
          result[item.link_type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.link_type].push(item);
        return result;
      }, {});

      setartistUrlRelation(groupedData);

      dispatch(resetgetArtistUrl());
    }
  }, [artistUrlData]);

  useEffect(() => {
    if (artistReleaseGroupCode == 200) {
      setartistReleaseGroup(artistReleaseGroupData);
      dispatch(resetGetArtistReleaseGroup());
    }
  }, [artistReleaseGroupData]);

  useEffect(() => {
    if (artistReleaseCode == 200) {
      setartistRelease(artistReleaseData);
      dispatch(resetGetArtistRelease());
    }
  }, [artistReleaseData]);

  console.log("artistInfo==========", artistInfo);
  console.log("artistTag==========", artistTag);
  console.log("artistUrlData==========", artistUrlData);
  console.log("artistReleaseGroupData==========", artistReleaseGroupData);

  const [isIndexSelected, setisIndexSelected] = useState(0);
  const [isRelationClicked, setisRelationClicked] = useState(0);

  const handleTabChange = (index) => {
    console.log("index=============", index);

    if (index == 1 && isIndexSelected == 0) {
      setisIndexSelected(1);
      let reqdata = {
        artist_gid: gid,
        page: 1,
        limit: 25,
      };
      dispatch(getArtistRelease(reqdata));
    }

    if (index == 3 && isRelationClicked == 0) {
      setisRelationClicked(1);
      let reqdata = {
        artist_type: artistInfo.artist_type,
        artist_gid: gid,
      };
      dispatch(getArtistRelationship(reqdata));
    }
  };

  const handleReleaseGroupClick = (cell, row, id) => {
    navigate(`/releaseGroup/${id}`, {
      state: {
        release_group_gid: id,
      },
    });
  };

  const handleReleaseClick = (cell, row, id) => {
    navigate(`/release/${id}`, {
      state: {
        release_gid: id,
      },
    });
  };

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  const calculateStars = (value) => {
    const rating = value / 20; // Convert 0-100 to a 0-5 scale
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return { fullStars, halfStar, emptyStars };
  };

  // Component to render star icons based on the rating
  const StarRating = ({ rating }) => {
    const { fullStars, halfStar, emptyStars } = calculateStars(rating);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FaStar key={`full-${i}`} style={{ color: "gold" }} />
          ))}
        {halfStar === 1 && (
          <FaRegStarHalfStroke key="half" style={{ color: "gold" }} />
        )}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FaRegStar key={`empty-${i}`} style={{ color: "#ddd" }} />
          ))}
      </div>
    );
  };

  const BaseExample = useMemo(() => {
    return (
      <Grid
        data={artistReleaseGroup}
        columns={[
          {
            id: "gid",
            name: "gid",
            hidden: true,
          },
          {
            id: "artist_credit_names",
            name: "artist_credit_names",
            hidden: true,
          },
          {
            id: "first_release_date_year",
            name: "Year",
          },
          {
            id: "name",
            name: "Title",
            formatter: (cell, row) =>
              _(
                <div>
                  <p
                    className="linkHover"
                    style={{ cursor: "pointer", margin: 0 }}
                    onClick={() =>
                      handleReleaseGroupClick(cell, row, row.cells[0]["data"])
                    }
                  >
                    {cell}
                  </p>
                </div>
              ),
          },
          {
            id: "Artist",
            name: "Artist",
            width: "400px",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ cursor: "pointer", margin: 0 }}>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <label
                          className="linkHover"
                          style={{ cursor: "pointer", margin: 0 }}
                          key={k}
                          onClick={() => handleArtistNameClick(i.artist_gid)}
                        >
                          {i.name}
                          {k != row.cells[1]["data"].length - 1 && ", "}
                        </label>
                      </>
                    ))}
                  </p>
                </div>
              ),
          },
          // {
          //   id: "rating",
          //   name: "Rating",
          //   formatter: (cell, row) =>
          //     _(
          //       <div>
          //         <StarRating rating={cell} />{" "}
          //       </div>
          //     ),
          // },
          {
            id: "release_count",
            name: "Releases",
          },
        ]}
        // search={true}
        autoWidth={true}
      />
    );
  }, [artistReleaseGroup]);

  const socialTypeshash = {
    discogs: 1,
    "official homepage": 1,
    myspace: 1,
    vk: 1,
    facebook: 1,
    bandcamp: 1,
    reverbnation: 1,
    twitter: 1,

    instagram: 1,
    songkick: 1,
    youtube: 1,
    "youtube music": 1,
    "amazon music": 1,
    "apple music": 1,
    spotify: 1,
  };

  const ArtistRelease = useMemo(() => {
    return (
      <Grid
        data={artistRelease}
        columns={[
          {
            id: "gid",
            name: "gid",
            hidden: true,
          },
          {
            id: "artist_credit_names",
            name: "artist_credit_names",
            hidden: true,
          },
          {
            id: "country_name",
            name: "country_name",
            hidden: true,
          },
          {
            id: "date_year",
            name: "date_year",
            hidden: true,
          },
          {
            id: "date_month",
            name: "date_month",
            hidden: true,
          },
          {
            id: "date_day",
            name: "date_day",
            hidden: true,
          },
          {
            id: "name",
            name: "Release",
            formatter: (cell, row) =>
              _(
                <div>
                  <p
                    className="linkHover"
                    style={{ cursor: "pointer", margin: 0 }}
                    onClick={() =>
                      handleReleaseClick(cell, row, row.cells[0]["data"])
                    }
                  >
                    {cell}
                  </p>
                </div>
              ),
          },

          {
            id: "name",
            name: "Artist",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ cursor: "pointer", margin: 0 }}>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <label
                          className="linkHover"
                          style={{ cursor: "pointer", margin: 0 }}
                          key={k}
                          onClick={() => handleArtistNameClick(i.artist_gid)}
                        >
                          {i.name}
                          {k != row.cells[1]["data"].length - 1 && ", "}
                        </label>
                      </>
                    ))}
                  </p>
                </div>
              ),
          },
          {
            id: "format",
            name: "Format",
          },
          {
            id: "track_count",
            name: "Tracks",
          },
          {
            id: "Country/Date",
            name: "Country/Date",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ margin: 0 }}>
                    {row.cells[2]["data"]} {row.cells[3]["data"]}-
                    {row.cells[4]["data"]}-{row.cells[5]["data"]}
                  </p>
                </div>
              ),
          },
          {
            id: "label",
            name: "Label",
          },
          {
            id: "barcode",
            name: "Barcode",
          },
          {
            id: "catalog_number",
            name: "Catalog",
          },
        ]}
        // search={true}
        autoWidth={true}
      />
    );
  }, [artistRelease]);

  const [pagecount, setpagecount] = useState(artistReleaseCurrentPage);
  const [inputcount, setinputcount] = useState(1);
  const [pagesizedata, setpagesizedata] = useState(0);

  useEffect(() => {
    setpagecount(artistReleaseCurrentPage);
  }, [artistReleaseCurrentPage]);

  const previousPage = () => {
    if (pagecount > 1) {
      setpagesizedata(1);
      setpagecount(pagecount - 1);

      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: pagecount - 1,
      };
      dispatch(getArtistReleaseGroup(reqdata));
    }
  };

  const nextPage = () => {
    if (pagecount < artistReleaseTotalPage) {
      setpagesizedata(1);
      setpagecount(pagecount + 1);

      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: pagecount + 1,
      };
      dispatch(getArtistReleaseGroup(reqdata));
    }
  };

  const onChangeInInput = (event) => {
    setinputcount(event);
  };

  const onBlurInput = (event) => {
    if (event > 0 && event <= artistReleaseTotalPage) {
      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: event,
      };
      dispatch(getArtistReleaseGroup(reqdata));

      setpagecount(parseInt(event));
    }
  };

  //artistRelease
  const [pagecountRelease, setpagecountRelease] = useState(
    artistReleaseCurrentPage
  );
  const [inputcountRelease, setinputcountRelease] = useState(1);
  const [pagesizedataRelease, setpagesizedataRelease] = useState(0);

  useEffect(() => {
    setpagecountRelease(artistReleaseDataCurrentPage);
  }, [artistReleaseDataCurrentPage]);

  const previousPageRelease = () => {
    if (pagecountRelease > 1) {
      setpagesizedataRelease(1);
      setpagecountRelease(pagecountRelease - 1);

      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: pagecountRelease - 1,
      };
      dispatch(getArtistRelease(reqdata));
    }
  };

  const nextPageRelease = () => {
    if (pagecountRelease < artistReleaseDataTotalPage) {
      setpagesizedataRelease(1);
      setpagecountRelease(pagecountRelease + 1);

      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: pagecountRelease + 1,
      };
      dispatch(getArtistRelease(reqdata));
    }
  };

  const onChangeInInputRelease = (event) => {
    setinputcountRelease(event);
  };

  const onBlurInputRelease = (event) => {
    if (event > 0 && event <= artistReleaseDataTotalPage) {
      let reqdata = {
        artist_gid: gid,
        limit: 25,
        page: event,
      };
      dispatch(getArtistRelease(reqdata));

      setpagecountRelease(parseInt(event));
    }
  };

  return (
    <Row>
      <Col md="2">
        <div className="leftMainDiv">
          <div>
            <p className="infoHeading">Artist Information</p>
            <p className="infoText">
              <strong>Sort name:</strong> {artistInfo.sort_name}
            </p>
            <p className="infoText">
              <strong>Type:</strong> {artistInfo.artist_type}
            </p>
            <p className="infoText">
              <strong>Gender:</strong> {artistInfo.gender}
            </p>
            <p className="infoText">
              <strong>Born:</strong> {artistInfo.born}
            </p>
            <p className="infoText">
              <strong>Born in:</strong> {artistInfo.born_in}
            </p>
            <p className="infoText">
              <strong>Area:</strong> {artistInfo.area}
            </p>
          </div>
          <div>
            <p className="infoHeading">Tags</p>
            {Object.entries(artistTag).map(([groupName, items]) => (
              <div key={groupName} style={{ marginBottom: "10px" }}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <div>
            <p className="infoHeading">Social Links</p>
            <ul
              style={{
                listStyle: "none",
                padding: "0px",
                textTransform: "capitalize",
              }}
            >
              {Array.isArray(artistUrl) &&
                artistUrl.map((i, k) =>
                  socialTypeshash[
                    i.type ? i.type.toLowerCase() : i.link_type.toLowerCase()
                  ] ? (
                    <li>
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        src={
                          i.type === false &&
                          i.link_type === "official homepage"
                            ? homeSweetHome // Replace with the path to your static image
                            : `https://logo.clearbit.com/${
                                new URL(i.url).hostname
                              }`
                        }
                        // alt={i.type || i.link_type}
                      />
                      <a
                        href={i.url}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                        className="linkHover"
                        key={k}
                      >
                        {/* {typeToIcon[i.type] || <TbWorld />} */}

                        {i.type ? i.type : i.link_type}
                      </a>
                    </li>
                  ) : (
                    ""
                  )
                )}
            </ul>
          </div>
        </div>
      </Col>

      <Col md="10" style={{ borderLeft: "1px solid #ccc" }}>
        <div className="nameDiv">
          <TbUserPentagon size={"40px"} color="#5B7EF0" />
          <div>
            <strong className="artistName">
              {artistInfo.name}{" "}
              {artistInfo.comment != "" && (
                <span
                  style={{ color: "grey", fontSize: "14px", fontWeight: "500" }}
                >
                  ({artistInfo.comment})
                </span>
              )}
            </strong>
            <p style={{ margin: 0 }}>~{artistInfo.artist_type}</p>
          </div>
        </div>
        <Tabs
          style={{ marginTop: "30px" }}
          defaultIndex={0}
          onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Releases</Tab>
            <Tab>Tags</Tab>
            <Tab>Relationships</Tab>
          </TabList>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Discography
              </p>
              {BaseExample}
              <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={previousPage}
                      disabled={pagecount == 1 ? true : false}
                    >
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Page{" "}
                  <strong>
                    {pagecount} of {artistReleaseTotalPage}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    // max={orders.last_page}
                    value={inputcount}
                    defaultValue={1}
                    onChange={(e) => onChangeInInput(e.target.value)}
                    onBlur={(e) => onBlurInput(e.target.value)}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={nextPage}
                      disabled={
                        pagecount == artistReleaseTotalPage ? true : false
                      }
                    >
                      {">"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <p
                style={{
                  color: "rgb(91, 126, 240)",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Releases
              </p>
              {ArtistRelease}

              <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={previousPageRelease}
                      disabled={pagecountRelease == 1 ? true : false}
                    >
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Page{" "}
                  <strong>
                    {pagecountRelease} of {artistReleaseDataTotalPage}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    // max={orders.last_page}
                    value={inputcountRelease}
                    defaultValue={1}
                    onChange={(e) => onChangeInInputRelease(e.target.value)}
                    onBlur={(e) => onBlurInputRelease(e.target.value)}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="primary"
                      onClick={nextPageRelease}
                      disabled={
                        pagecountRelease == artistReleaseTotalPage
                          ? true
                          : false
                      }
                    >
                      {">"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPanel>
          <TabPanel>
            <p className="infoHeading">Tags</p>
            {Object.entries(artistTag).map(([groupName, items]) => (
              <div key={groupName} style={{ marginBottom: "10px" }}>
                <strong>{groupName}</strong>
                {items.map((item) => (
                  <p style={{ margin: 0 }} key={item.tag_id}>
                    {item.name}
                  </p>
                ))}
              </div>
            ))}
          </TabPanel>
          <TabPanel>
            <p className="infoHeading">Relationships</p>
            <div style={{ marginLeft: "30px" }}>
              {Object.entries(artistRelationship).map(([groupName, items]) => (
                <div key={groupName} style={{ marginBottom: "10px" }}>
                  <strong>
                    {groupName == "0"
                      ? `${
                          artistInfo.artist_type == "Person"
                            ? "Collaborator on"
                            : "Collaborators"
                        }`
                      : groupName == "1"
                      ? `${
                          artistInfo.artist_type == "Person"
                            ? "Member of"
                            : "Members"
                        }`
                      : groupName == "2"
                      ? `${
                          artistInfo.artist_type == "Person"
                            ? "Original member of"
                            : "Original members"
                        }`
                      : "Others"}
                    :
                  </strong>
                  <ul
                    style={{
                      marginLeft: "30px",
                      marginTop: "5px",
                      listStyle: "none",
                      padding: "0px",
                    }}
                  >
                    {items.map((i) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <FaUser color="grey" />
                        <span
                          className="linkHover"
                          style={{
                            cursor: "pointer",
                            color: "#007bff",
                            fontWeight: "500",
                          }}
                          onClick={() => handleArtistNameClick(i.gid)}
                        >
                          {i.name}
                        </span>{" "}
                        {i.comment && (
                          <span style={{ color: "grey" }}>({i.comment}) </span>
                        )}
                        {i.begin_date_year && (
                          <span>
                            (from {i.begin_date_year}{" "}
                            {i.end_date_year
                              ? `until ${i.end_date_year}`
                              : "to present"}
                            )
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <ul
                style={{
                  listStyle: "none",
                  padding: "0px",
                }}
              >
                {Object.entries(artistUrlRelation).map(([groupName, items]) => (
                  <>
                    <strong style={{ textTransform: "capitalize" }}>
                      {groupName}:
                    </strong>
                    <div
                      style={{
                        marginLeft: "30px",
                        marginTop: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {items.map((i, k) => (
                        <li>
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                              verticalAlign: "middle",
                            }}
                            src={
                              i.type === false &&
                              i.link_type === "official homepage"
                                ? homeSweetHome // Replace with the path to your static image
                                : `https://logo.clearbit.com/${
                                    new URL(i.url).hostname
                                  }`
                            }
                          />
                          <a
                            href={i.url}
                            target="_blank"
                            className="linkHover"
                            key={k}
                          >
                            {i.url}
                          </a>
                        </li>
                      ))}
                    </div>
                  </>
                ))}
              </ul>
              {/* {artistRelationship.map((i, k) => (
                <p>
                  <span
                    className="linkHover"
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      fontWeight: "500",
                    }}
                    onClick={() => handleArtistNameClick(i.gid)}
                  >
                    {i.name}
                  </span>{" "}
                  {i.comment && (
                    <span style={{ color: "grey" }}>({i.comment}) </span>
                  )}
                  {i.begin_date_year &&
                    i.begin_date_month &&
                    i.begin_date_day && (
                      <span>
                        ({i.begin_date_year}-{i.begin_date_month}-
                        {i.begin_date_day})
                      </span>
                    )}
                </p>
              ))} */}
            </div>
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Artist;
