import React, { useEffect, useMemo, useState } from "react";
import CommonNewGrid from "../../Component/CommonNewGrid";
import { useDispatch, useSelector } from "react-redux";
import { Grid, _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import {
  getArtist,
  getArtistRelease,
  getArtistReleaseGroup,
  getArtistTag,
  getArtistUrl,
  getRelease,
  getReleaseGroup,
  getReleaseGroupTag,
  getReleaseGroupTrack,
  getReleaseGroupUrl,
  getSearch,
  resetArtist,
  resetRelease,
  resetSearch,
} from "../../Store/actions";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";

const SearchRelease = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { searchtype, searchquery } = useParams();

  const {
    searchData,
    searchCode,
    artistCode,
    searchType,
    searchRecords,
    searchPages,
    searchCurrentPages,
  } = useSelector((state) => ({
    searchData: state.searchReducer.searchData,
    searchCode: state.searchReducer.searchCode,
    searchType: state.searchReducer.searchType,
    artistCode: state.artistReducer.artistCode,
    searchRecords: state.searchReducer.searchRecords,
    searchPages: state.searchReducer.searchPages,
    searchCurrentPages: state.searchReducer.searchCurrentPages,
  }));

  const [artistSearchData, setartistSearchData] = useState([]);

  console.log("searchData==========", searchData);

  useEffect(() => {
    if (Array.isArray(searchData) && searchType == "release") {
      setartistSearchData(searchData);
      dispatch(resetSearch());
    }
  }, [searchData]);

  const [artistName, setartistName] = useState(null);
  const [artistType, setartistType] = useState(null);
  const [artistId, setartistId] = useState(null);

  const handleReleaseClick = (cell, row, id, type) => {
    console.log("cell==========", cell);
    console.log("row==========", row);
    console.log("id==========", id);
    // console.log("type==========", type);

    setartistName(cell);
    // setartistType(type);
    setartistId(id);

    let reqdata = {
      release_gid: id,
    };

    navigate(`/release/${id}`, {
      state: {
        release_gid: id,
      },
    });
  };

  const handleArtistClick = (cell, row, id, type) => {
    navigate(`/artist/${id}`, {
      state: {
        artistName: cell,
        artistType: type,
        artistId: id,
      },
    });
  };

  const BaseExample = useMemo(() => {
    return (
      <React.Fragment>
        <Grid
          data={artistSearchData}
          columns={[
            {
              id: "gid",
              name: "id",
              hidden: true,
            },
            {
              id: "artist_credit_names",
              name: "artist_credit_names",
              hidden: true,
            },
            {
              id: "name",
              name: "Name",
              formatter: (cell, row) =>
                _(
                  <div>
                    <p
                      className="linkHover"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() =>
                        handleReleaseClick(
                          cell,
                          row,
                          row.cells[0]["data"],
                          row.cells[3]["data"]
                        )
                      }
                    >
                      {cell}
                    </p>
                  </div>
                ),
              width: "auto",
            },
            {
              id: "Artist",
              name: "Artist",
              formatter: (cell, row) =>
                _(
                  <div>
                    {row.cells[1]["data"].map((i, k) => (
                      <>
                        <span
                          style={{ cursor: "pointer", margin: 0 }}
                          className="linkHover"
                          onClick={() =>
                            handleArtistClick(
                              cell,
                              row,
                              i.artist_gid,
                              row.cells[3]["data"]
                            )
                          }
                        >
                          {i.name}
                        </span>
                        {i.join_phrase}
                      </>
                    ))}
                  </div>
                ),
              width: "auto",
            },
            {
              id: "format",
              name: "Format",
              width: "auto",
            },
            {
              id: "track_count",
              name: "Tracks",
              width: "auto",
            },
            {
              id: "label",
              name: "Label",
              width: "auto",
            },
            {
              id: "catalog_number",
              name: "Catalog",
              width: "auto",
            },
            {
              id: "barcode",
              name: "Barcode",
              width: "auto",
            },
            {
              id: "language",
              name: "Language",
              width: "auto",
            },
            {
              id: "status",
              name: "status",
              width: "auto",
            },
          ]}
          // search={true}
          // sort={true}
          // height={height}
          // style={{ height: { height }, overflowY: "scroll" }}
          pagination={{ enabled: true, limit: 25 }}
        />
      </React.Fragment>
    );
  }, [artistSearchData]);

  const [pagecount, setpagecount] = useState(searchCurrentPages);
  const [inputcount, setinputcount] = useState(1);
  const [pagesizedata, setpagesizedata] = useState(0);

  useEffect(() => {
    setpagecount(searchCurrentPages);
  }, [searchCurrentPages]);

  const previousPage = () => {
    if (pagecount > 1) {
      setpagesizedata(1);
      setpagecount(pagecount - 1);

      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: pagecount - 1,
      };
      dispatch(getSearch(reqdata));
    }
  };

  const nextPage = () => {
    if (pagecount < searchPages) {
      setpagesizedata(1);
      setpagecount(pagecount + 1);

      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: pagecount + 1,
      };
      dispatch(getSearch(reqdata));
    }
  };

  const onChangeInInput = (event) => {
    setinputcount(event);
  };

  const onBlurInput = (event) => {
    if (event > 0 && event <= searchPages) {
      let reqdata = {
        query: searchquery,
        type: searchtype,
        limit: 25,
        page: event,
      };
      dispatch(getSearch(reqdata));

      setpagecount(parseInt(event));
    }
  };

  return (
    <>
      {/* <div>
        <h3>Search Result</h3>
      </div> */}
      <Card>
        <CardHeader>
          <h3>Search Result</h3>
        </CardHeader>
        <CardBody>{BaseExample}</CardBody>
        <CardFooter>
          <Row className="justify-content-md-end justify-content-center align-items-center pe-2">
            {/* <Col className="col-md-auto">
              <Select
                options={dropdownmenu}
                onChange={(e) => handledropdown(e)}
              />
            </Col> */}
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={pagecount == 1 ? true : false}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pagecount} of {searchPages}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                // max={orders.last_page}
                value={inputcount}
                defaultValue={1}
                onChange={(e) => onChangeInInput(e.target.value)}
                onBlur={(e) => onBlurInput(e.target.value)}
              />
            </Col>
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={pagecount == searchPages ? true : false}
                >
                  {">"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default SearchRelease;
