import React, { useEffect, useRef, useState } from "react";
import { TbUserPentagon } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import { Grid, _ } from "gridjs-react";
import "./Release.css";
import { MdLibraryMusic } from "react-icons/md";
import {
  getRelease,
  getReleaseGroupTrack,
  getReleaseTag,
  getReleaseUrl,
} from "../../Store/actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SiApplemusic, SiYoutubemusic } from "react-icons/si";
import axios from "axios";
import { FaPlay, FaPlayCircle } from "react-icons/fa";
import ImageWithFallbackArtist from "../../Component/ImageWithFallbackArtist";
import { Audio } from "react-loader-spinner";
import { usePlayer } from "../../Component/Yt/PlayerContext";

const Release = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { gid } = useParams();
  // const id = location.state?.id || "No message";
  const [randomColor, setrandomColor] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        release_gid: gid,
      };

      dispatch(getRelease(reqdata));
      dispatch(getReleaseGroupTrack(reqdata));
      dispatch(getReleaseUrl(reqdata));

      const color1 = getRandomColor();
      const color2 = getRandomColor();
      setrandomColor(`linear-gradient(135deg, ${color1}, ${color2})`);
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]);

  const {
    releaseCode,
    releaseData,
    releaseGroupTrackData,
    releaseUrlData,
    releaseTagData,
  } = useSelector((state) => ({
    releaseTagData: state.releaseReducer.releaseTagData,
    releaseUrlData: state.releaseReducer.releaseUrlData,
    releaseCode: state.releaseReducer.releaseCode,
    releaseData: state.releaseReducer.releaseData,
    releaseGroupTrackData: state.releaseReducer.releaseGroupTrackData,
  }));

  const [releaseInfo, setreleaseInfo] = useState({
    release_id: "",
    release: "",
    artist_credit_id: "",
    artist_credit_name: "",
    label: null,
    label_comment: null,
    format: "",
    barcode: null,
    language: "",
    type: "",
    secondary_type: "",
    length: "",
    cover_art_url: "",
    artist_credit_names: [],
  });

  const [releaseTrack, setreleaseTrack] = useState([]);
  const [releaseUrl, setreleaseUrl] = useState([]);
  const [releaseTag, setreleaseTag] = useState([]);
  const [artistnames, setartistnames] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const groupedData = releaseTagData.reduce((result, item) => {
      // Check if the group (item.type) already exists in the result
      if (!result[item.type]) {
        result[item.type] = []; // If not, create an empty array for this group
      }
      // Add the item to the appropriate group
      result[item.type].push(item);
      return result;
    }, {});

    setreleaseTag(groupedData);
  }, [releaseTagData]);

  useEffect(() => {
    setreleaseTrack(releaseGroupTrackData);
  }, [releaseGroupTrackData]);

  useEffect(() => {
    setreleaseInfo(releaseData);

    if (releaseData && releaseData.artist_credit_names) {
      let nameString = "";
      for (let i = 0; i < releaseData.artist_credit_names.length; i++) {
        const element = releaseData.artist_credit_names[i];
        nameString += element.name + element.join_phrase + " ";
      }
      console.log("nameString====", nameString);
      setartistnames(nameString);
    }
  }, [releaseData]);

  useEffect(() => {
    setreleaseUrl(releaseUrlData);
  }, [releaseUrlData]);

  function convertMillisecondsToMinutes(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Pad single-digit seconds with a leading zero
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${minutes}:${formattedSeconds}`;
  }

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  const [isIndexSelected, setisIndexSelected] = useState(0);

  const handleTabChange = (index) => {
    console.log("index=============", index);

    if (index == 1 && isIndexSelected == 0) {
      setisIndexSelected(1);
      let reqdata = {
        release_gid: gid,
      };
      dispatch(getReleaseTag(reqdata));
    }
  };

  const [videoId, setvideoId] = useState(null);
  const [playId, setplayId] = useState(null);

  const { playVideo, setFullscreenPlayer, minimizePlayer } = usePlayer();

  const fetchYoutubeVideo = async (q, artist, key) => {
    console.log(q);
    setplayId(key);

    console.log("nameString inner=====", artistnames);

    let nameString = "";
    for (let i = 0; i < artist.length; i++) {
      const element = artist[i];
      nameString += element.name + element.join_phrase + " ";
    }

    try {
      // const response = await axios.get(
      //   `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${q} ${nameString}&videoEmbeddable=true&type=video&key=AIzaSyAupeXohkZCNCUE2-ISAxEAacEvc4Eq8is`
      // );
      // console.log(response.items[0]["id"]["videoId"]);
      // setvideoId(response.items[0]["id"]["videoId"]);

      const response = await axios.get(
        `https://soundwavebe.avanzarsolution.com/api/yt/search?q=${q} ${nameString}`
      );
      console.log(response[0]["id"]);
      console.log(response);
      setvideoId(response[0]["id"]);
      playVideo(response[0]["id"]);
      // initializePlayer(response[0]["id"]);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleRecordingClick = (i) => {
    navigate(`/recording/${i.recording_gid}`);
  };

  const [hasError, setHasError] = useState(false);

  // Helper to generate random colors
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);

  // Dynamically load the YouTube Iframe API script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;
    document.body.appendChild(script);

    // Create the player once the API is ready
    window.onYouTubeIframeAPIReady = () => {
      initializePlayer(videoId);
    };

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  // Initialize the YouTube player
  const initializePlayer = (videoId) => {
    if (window.YT) {
      if (playerRef.current) {
        // If player exists, load a new video
        playerRef.current.loadVideoById(videoId);
        playerRef.current.playVideo();
      } else {
        // Create a new player
        playerRef.current = new window.YT.Player("youtube-player", {
          height: "500",
          width: "100%",
          videoId: videoId,
          events: {
            onReady: () => {
              playerRef.current.playVideo();
              startFetchingTime();
            },
            onStateChange: onPlayerStateChange,
          },
        });
      }
    }
  };

  // Start fetching the current time of the video
  const startFetchingTime = () => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const time = playerRef.current.getCurrentTime();

        // localStorage.setItem("playerTime", time);
        // setCurrentTime(time);
      }
    }, 1000);

    // Cleanup interval when the component unmounts
    return () => clearInterval(interval);
  };

  // When the player state changes (e.g., playing, paused)
  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      startFetchingTime();
    }
  };

  // console.log("currentTime==========", currentTime);

  return (
    <Row>
      <Col md="10">
        <div className="nameDiv">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: randomColor,
              color: "#fff",
              fontSize: "16px",
              textAlign: "center",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              cursor: "pointer",
              width: "125px",
              height: "125px",
              borderRadius: "5px",
            }}
          >
            {!hasError ? (
              <img
                src={
                  releaseInfo &&
                  releaseInfo.cover_art_url != "" &&
                  releaseInfo.cover_art_url
                }
                alt={releaseInfo.release}
                onError={() => setHasError(true)}
                style={{ width: "125px", height: "125px", borderRadius: "5px" }}
              />
            ) : (
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {releaseInfo.release?.slice(0, 20)}
              </span>
            )}
          </div>
          <div>
            <strong className="artistName">{releaseInfo.release}</strong>
            <p style={{ margin: 0 }}>
              ~Release group by{" "}
              {releaseInfo &&
                releaseInfo.artist_credit_names &&
                releaseInfo.artist_credit_names.map((i, k) => (
                  <>
                    <span
                      style={{
                        margin: 0,
                        color: "#5B7EF0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleArtistNameClick(i.artist_gid)}
                    >
                      {i.name}{" "}
                    </span>
                    {i.join_phrase}
                  </>
                ))}
            </p>
          </div>
        </div>
        <Tabs
          style={{ marginTop: "30px" }}
          defaultIndex={0}
          onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Tags</Tab>
          </TabList>
          <TabPanel>
            <p
              style={{
                color: "rgb(91, 126, 240)",
                marginTop: "15px",
                marginBottom: "15px",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              Tracklist
            </p>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #333",
                  paddingBottom: "8px",
                  marginBottom: "8px",
                }}
              >
                <div style={{ display: "flex", gap: "20px" }}>
                  <span className="tableHeading">#</span>
                  <span className="tableHeading">Name</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span className="tableHeading">Length</span>
                  <span className="tableHeading">Play</span>
                </div>
              </div>

              <div className="releaseDiv">
                {releaseTrack.map((item, k) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      backgroundColor:
                        hoveredIndex === k
                          ? "#007bff"
                          : k === playId
                          ? "#c7c7c7"
                          : "transparent",
                      borderRadius: "10px",
                      padding: "5px",
                      alignItems: "center",
                    }}
                    onMouseEnter={() => setHoveredIndex(k)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {/* {playId === k ? (
                        <Audio
                          height="20"
                          width="20"
                          color={hoveredIndex === k ? "white" : "#007bff"}
                          ariaLabel="audio-loading"
                          wrapperStyle={{}}
                          wrapperClass="wrapper-class"
                          visible={true}
                        />
                      ) : hoveredIndex === k ? (
                        <FaPlayCircle
                          onClick={() =>
                            fetchYoutubeVideo(
                              item.title,
                              item.artist_credit_names,
                              k
                            )
                          }
                          style={{ fontSize: "18px" }}
                          color="white"
                        />
                      ) : (
                        <span>{k + 1}</span>
                      )} */}
                      <span
                        style={{
                          color: hoveredIndex === k ? "white" : "black",
                        }}
                      >
                        {k + 1}
                      </span>

                      <span
                        style={{
                          color: hoveredIndex === k ? "white" : "black",
                        }}
                        onClick={() => handleRecordingClick(item)}
                      >
                        {item.title}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "25px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          color: hoveredIndex === k ? "white" : "black",
                        }}
                      >
                        {convertMillisecondsToMinutes(item.length)}
                      </p>
                      {playId === k ? (
                        <Audio
                          height="20"
                          width="20"
                          color={hoveredIndex === k ? "white" : "#007bff"}
                          ariaLabel="audio-loading"
                          wrapperStyle={{}}
                          wrapperClass="wrapper-class"
                          visible={true}
                        />
                      ) : (
                        <FaPlayCircle
                          onClick={() =>
                            fetchYoutubeVideo(
                              item.title,
                              item.artist_credit_names,
                              k
                            )
                          }
                          style={{ fontSize: "18px" }}
                          color={hoveredIndex === k ? "white" : "#007bff"}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <p
              style={{
                color: "rgb(91, 126, 240)",
                marginTop: "15px",
                marginBottom: "15px",
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              Tags
            </p>
            <div>
              {Object.entries(releaseTag).map(([groupName, items]) => (
                <div key={groupName} style={{ marginBottom: "10px" }}>
                  <strong>{groupName}</strong>
                  {items.map((item) => (
                    <p style={{ margin: 0 }} key={item.tag_id}>
                      {item.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
        <hr />
        <div className="infoDivs">
          <div>
            <p className="infoHeading">External links</p>
            {releaseUrl.map((i, k) => (
              <a
                href={i.url}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
              >
                <p className="linkHover" style={{ margin: 0 }} key={k}>
                  {i.type}
                </p>
              </a>
            ))}
          </div>
          <div>
            <p className="infoHeading">Release information</p>
            <p className="infoText">
              Format:{" "}
              <label style={{ fontWeight: "400" }}>{releaseData.format}</label>
            </p>
            <p className="infoText">
              Length:{" "}
              <label style={{ fontWeight: "400" }}>
                {convertMillisecondsToMinutes(releaseData.length)}{" "}
              </label>
            </p>
          </div>
          <div>
            <p className="infoHeading">Additional details</p>
            <p className="infoText">
              Type:{" "}
              <label style={{ fontWeight: "400" }}>{releaseData.type}</label>
            </p>
            <p className="infoText">
              Language:{" "}
              <label style={{ fontWeight: "400" }}>
                {releaseData.language}{" "}
              </label>
            </p>
          </div>
        </div>
      </Col>

      <Col md="2" style={{ borderLeft: "1px solid #ccc" }}>
        <div
          style={{
            position: "relative",
            width: "300px",
            height: "250px",
            backgroundColor: "#007bff",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            color: "white",
          }}
        >
          <p style={{ fontSize: "20px" }}>Ad</p>
        </div>
        <div
          style={{
            position: "relative",
            width: "300px",
            height: "250px",
            backgroundColor: "#007bff",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: "10px",
            color: "white",
          }}
        >
          <p style={{ fontSize: "20px" }}>Ad</p>
        </div>
      </Col>
    </Row>
  );
};

export default Release;
