import {
  GET_SEARCH,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_FAIL,
  RESET_SEARCH,
} from "./actionType";

const INIT_STATE = {
  searchData: [],
  searchCode: 0,
  searchType: "",
  searchRecords: 0,
  searchPages: 0,
  searchCurrentPages: 1,
};

const searchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SEARCH_SUCCESS:
      console.log(action.payload.data);
      switch (action.payload.actionType) {
        case GET_SEARCH:
          return {
            ...state,
            searchData: action.payload.data.data,
            searchRecords: action.payload.data.total_records,
            searchPages: action.payload.data.total_pages,
            searchCurrentPages: action.payload.data.current_page,
            searchCode: action.payload.data.code,
            searchType: action.payload.data.type,
          };
        default:
          return { ...state };
      }
    case GET_SEARCH_FAIL:
      switch (action.payload.actionType) {
        case GET_SEARCH:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_SEARCH:
      return {
        ...state,
        // searchData: [],
        searchCode: 0,
        // searchType: "",
      };

    default:
      return state;
  }
};

export default searchReducer;
