import React from "react";
import Draggable from "react-draggable";
import { Button } from "reactstrap";
import { RiDragMove2Fill } from "react-icons/ri";

const DraggableYouTubePlayer = ({
  videoId,
  isMinimized,
  isDraggable,
  onClose,
}) => {
  if (!videoId) return null;

  const playerStyles = isMinimized
    ? {
        position: "fixed",
        bottom: "10px",
        right: "10px",
        width: "200px",
        height: "150px",
        backgroundColor: "black",
        border: "1px solid #ccc",
        borderRadius: "10px",
        zIndex: 1000,
      }
    : {
        position: "relative",
        width: "100%",
        height: "500px",
        backgroundColor: "black",
        border: "1px solid #ccc",
        borderRadius: "10px",
      };

  const PlayerWrapper = isDraggable ? Draggable : React.Fragment;

  return (
    <PlayerWrapper {...(isDraggable ? { bounds: "parent" } : {})}>
      <Draggable handle=".drag-handle">
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "400px",
            height: "215px",
            zIndex: 1000, // Adjust z-index while dragging
          }}
        >
          {/* Drag Handle Button */}
          <Button
            className="drag-handle"
            style={{
              position: "absolute",
              top: "-40px",
              right: "0px",
              padding: "8px 13px",
              cursor: "move",
              zIndex: 1,
              borderRadius: 0,
              border: 0,
              backgroundColor: "#00000094",
            }}
          >
            <RiDragMove2Fill />
          </Button>

          {/* YouTube Iframe */}
          <iframe
            width="400"
            height="215"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            //   src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Draggable>
    </PlayerWrapper>
  );
};

export default DraggableYouTubePlayer;
