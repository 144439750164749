import {
  GET_RELEASE,
  GET_RELEASE_SUCCESS,
  GET_RELEASE_FAIL,
  GET_RELEASE_GROUP,
  GET_RELEASE_GROUP_SUCCESS,
  GET_RELEASE_GROUP_FAIL,
  GET_RELEASE_GROUP_RELEASE,
  GET_RELEASE_GROUP_RELEASE_SUCCESS,
  GET_RELEASE_GROUP_RELEASE_FAIL,
  GET_RELEASE_GROUP_URL,
  GET_RELEASE_GROUP_URL_SUCCESS,
  GET_RELEASE_GROUP_URL_FAIL,
  GET_RELEASE_GROUP_TAG,
  GET_RELEASE_GROUP_TAG_SUCCESS,
  GET_RELEASE_GROUP_TAG_FAIL,
  GET_RELEASE_GROUP_TRACK,
  GET_RELEASE_GROUP_TRACK_SUCCESS,
  GET_RELEASE_GROUP_TRACK_FAIL,
  GET_RELEASE_URL,
  GET_RELEASE_URL_SUCCESS,
  GET_RELEASE_URL_FAIL,
  GET_RELEASE_TAG,
  GET_RELEASE_TAG_SUCCESS,
  GET_RELEASE_TAG_FAIL,
  GET_RECENT_RELEASE,
  GET_RECENT_RELEASE_SUCCESS,
  GET_RECENT_RELEASE_FAIL,
  RESET_RECENT_RELEASE,
  GET_NEW_RELEASE,
  GET_NEW_RELEASE_SUCCESS,
  GET_NEW_RELEASE_FAIL,
  RESET_NEW_RELEASE,
  GET_RECENT_RELEASE_FOOTER,
  GET_RECENT_RELEASE_FOOTER_SUCCESS,
  GET_RECENT_RELEASE_FOOTER_FAIL,
  RESET_RECENT_RELEASE_FOOTER,
  RESET_RELEASE_GROUP,
  RESET_RELEASE,
  RESET_GET_RELEASE_GROUP_RELEASE,
  RESET_GET_RELEASE_GROUP_TAG,
  RESET_GET_RELEASE_GROUP_URL,
} from "./actionType";

const INIT_STATE = {
  releaseData: [],
  recentReleaseData: [],
  recentReleaseFooterData: [],
  newReleaseData: [],
  recentReleaseCode: 0,
  recentReleaseFooterCode: 0,
  newReleaseCode: 0,
  releaseUrlData: [],
  releaseTagData: [],
  releaseCode: 0,
  releaseGroupData: [],
  releaseGroupCode: 0,
  releaseGroupUrlData: [],
  releaseGroupUrlCode: 0,
  releaseGroupReleaseData: [],
  releaseGroupReleaseCurrentPage: 1,
  releaseGroupReleaseTotalPage: 1,
  releaseGroupReleaseCode: 0,
  releaseGroupTagData: [],
  releaseGroupTagCode: 0,
  releaseGroupTrackData: [],
  releaseGroupCurrentPage: 1,
  releaseGroupTotalPage: 1,
};

const releaseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RELEASE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE:
          return {
            ...state,
            releaseData: action.payload.data.data,
            releaseCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case GET_RELEASE_URL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_URL:
          return {
            ...state,
            releaseUrlData: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_URL_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_URL:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case GET_RELEASE_TAG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_TAG:
          return {
            ...state,
            releaseTagData: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_TAG_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_TAG:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //releaseGroup
    case GET_RELEASE_GROUP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP:
          return {
            ...state,
            releaseGroupData: action.payload.data.data,
            releaseGroupCode: action.payload.data.code,
            releaseGroupCurrentPage: action.payload.data.current_page,
            releaseGroupTotalPage: action.payload.data.total_pages,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_GROUP_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //releaseGroupRelease
    case GET_RELEASE_GROUP_RELEASE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_RELEASE:
          return {
            ...state,
            releaseGroupReleaseData: action.payload.data.data,
            releaseGroupReleaseCode: action.payload.data.code,
            releaseGroupReleaseCurrentPage: action.payload.data.current_page,
            releaseGroupReleaseTotalPage: action.payload.data.total_pages,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_GROUP_RELEASE_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_RELEASE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //releaseGroupUrl
    case GET_RELEASE_GROUP_URL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_URL:
          return {
            ...state,
            releaseGroupUrlData: action.payload.data.data,
            releaseGroupUrlCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_GROUP_URL_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_URL:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //releaseGroupTag
    case GET_RELEASE_GROUP_TAG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_TAG:
          return {
            ...state,
            releaseGroupTagData: action.payload.data.data,
            releaseGroupTagCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_GROUP_TAG_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_TAG:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //releaseGroupTrack
    case GET_RELEASE_GROUP_TRACK_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_TRACK:
          return {
            ...state,
            releaseGroupTrackData: action.payload.data.data,
          };
        default:
          return { ...state };
      }
    case GET_RELEASE_GROUP_TRACK_FAIL:
      switch (action.payload.actionType) {
        case GET_RELEASE_GROUP_TRACK:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case GET_RECENT_RELEASE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RECENT_RELEASE:
          return {
            ...state,
            recentReleaseData: action.payload.data.data,
            recentReleaseCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RECENT_RELEASE_FAIL:
      switch (action.payload.actionType) {
        case GET_RECENT_RELEASE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //new
    case GET_NEW_RELEASE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_NEW_RELEASE:
          return {
            ...state,
            newReleaseData: action.payload.data.data,
            newReleaseCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_NEW_RELEASE_FAIL:
      switch (action.payload.actionType) {
        case GET_NEW_RELEASE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //footer
    case GET_RECENT_RELEASE_FOOTER_SUCCESS:
      console.log(
        "action.payload.actionType==========",
        action.payload.actionType
      );
      switch (action.payload.actionType) {
        case GET_RECENT_RELEASE_FOOTER:
          console.log(
            "action.payload.actionType==========",
            action.payload.data
          );
          return {
            ...state,
            recentReleaseFooterData: action.payload.data.data,
            recentReleaseFooterCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_RECENT_RELEASE_FOOTER_FAIL:
      switch (action.payload.actionType) {
        case GET_RECENT_RELEASE_FOOTER:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_NEW_RELEASE:
      return {
        ...state,
        newReleaseData: [],
        newReleaseCode: 0,
      };

    case RESET_RECENT_RELEASE:
      return {
        ...state,
        recentReleaseCode: 0,
        recentReleaseData: [],
      };

    case RESET_RECENT_RELEASE_FOOTER:
      return {
        ...state,
        recentReleaseFooterData: [],
        recentReleaseFooterCode: 0,
      };

    case RESET_RELEASE:
      return {
        ...state,
        releaseCode: 0,
      };

    case RESET_RELEASE_GROUP:
      return {
        ...state,
        releaseGroupCode: 0,
        releaseGroupData: [],
      };

    case RESET_GET_RELEASE_GROUP_RELEASE:
      return {
        ...state,
        releaseGroupReleaseData: [],
        releaseGroupReleaseCode: 0,
      };

    case RESET_GET_RELEASE_GROUP_URL:
      return {
        ...state,
        releaseGroupUrlData: [],
        releaseGroupUrlCode: 0,
      };

    case RESET_GET_RELEASE_GROUP_TAG:
      return {
        ...state,
        releaseGroupTagCode: 0,
        releaseGroupTrackData: [],
      };

    default:
      return state;
  }
};

export default releaseReducer;
