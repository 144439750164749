import React, { useEffect, useMemo, useState } from "react";
import { TbUserPentagon } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Button, Col, Row } from "reactstrap";
import { Grid, _ } from "gridjs-react";
import "./Recording.css";
import {
  getRecording,
  getRecordingList,
  getRecordingTag,
  resetRecording,
  resetRecordingList,
  resetRecordingTag,
} from "../../Store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegStar, FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import Draggable from "react-draggable";
import { RiDragMove2Fill } from "react-icons/ri";
import axios from "axios";
import { usePlayer } from "../../Component/Yt/PlayerContext";

const Recording = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { gid } = useParams();

  console.log("gid of artist", gid);

  useEffect(() => {
    const timer = setTimeout(() => {
      let reqdata = {
        recording_gid: gid,
      };

      dispatch(getRecording(reqdata));
      dispatch(getRecordingList(reqdata));
      dispatch(getRecordingTag(reqdata));
    }, 0);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [gid]);

  const {
    recordingData,
    recordingCode,
    recordingListData,
    recordingListCode,
    recordingTagData,
    recordingTagCode,
  } = useSelector((state) => ({
    recordingData: state.recordingReducer.recordingData,
    recordingCode: state.recordingReducer.recordingCode,
    recordingListData: state.recordingReducer.recordingListData,
    recordingListCode: state.recordingReducer.recordingListCode,
    recordingTagData: state.recordingReducer.recordingTagData,
    recordingTagCode: state.recordingReducer.recordingTagCode,
  }));

  const [recordingInfo, setrecordingInfo] = useState({
    recording_id: "",
    recording_gid: "",
    name: "",
    length: "",
    artist_credit_id: "",
    rating: "",
    rating_count: "",
    first_release_year: "",
    isrc: "",
    artist_credit_names: [],
  });

  const [recordingList, setrecordingList] = useState([]);
  const [artistnames, setartistnames] = useState("");
  const [recordingTag, setrecordingTag] = useState([]);

  useEffect(() => {
    if (recordingCode == 200) {
      setrecordingInfo(recordingData);

      if (recordingData && recordingData.release_artists) {
        let nameString = "";
        for (let i = 0; i < recordingData.release_artists.length; i++) {
          const element = recordingData.release_artists[i];
          nameString += element.name + element.join_phrase + " ";
        }
        console.log("nameString====", nameString);
        setartistnames(nameString);
      }

      dispatch(resetRecording());
    }
  }, [recordingCode]);

  useEffect(() => {
    if (recordingListCode == 200) {
      setrecordingList(recordingListData);
      dispatch(resetRecordingList());
    }
  }, [recordingListCode]);

  useEffect(() => {
    if (recordingTagCode == 200) {
      const groupedData = recordingTagData.reduce((result, item) => {
        // Check if the group (item.type) already exists in the result
        if (!result[item.type]) {
          result[item.type] = []; // If not, create an empty array for this group
        }
        // Add the item to the appropriate group
        result[item.type].push(item);
        return result;
      }, {});

      setrecordingTag(groupedData);

      dispatch(resetRecordingTag());
    }
  }, [recordingTagCode]);

  console.log("recordingData recordingInfo==========", recordingInfo);
  console.log("recordingCode==========", recordingCode);

  console.log("recordingListData==========", recordingListData);
  console.log("recordingListCode==========", recordingListCode);

  function convertMillisecondsToMinutes(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Pad single-digit seconds with a leading zero
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${minutes}:${formattedSeconds}`;
  }

  const calculateStars = (value) => {
    const rating = value / 20; // Convert 0-100 to a 0-5 scale
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return { fullStars, halfStar, emptyStars };
  };

  // Component to render star icons based on the rating
  const StarRating = ({ rating }) => {
    const { fullStars, halfStar, emptyStars } = calculateStars(rating);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <FaStar key={`full-${i}`} style={{ color: "gold" }} />
          ))}
        {halfStar === 1 && (
          <FaRegStarHalfStroke key="half" style={{ color: "gold" }} />
        )}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <FaRegStar key={`empty-${i}`} style={{ color: "#ddd" }} />
          ))}
      </div>
    );
  };

  const handleArtistNameClick = (id) => {
    navigate(`/artist/${id}`);
  };

  const handleReleaseClick = (id) => {
    navigate(`/release/${id}`);
  };

  const [videoId, setvideoId] = useState(null);

  const { playVideo, setFullscreenPlayer, minimizePlayer } = usePlayer();

  const fetchYoutubeVideo = async (q, artist) => {
    console.log(q);

    console.log("nameString inner=====", artistnames);

    let nameString = "";
    for (let i = 0; i < artist.length; i++) {
      const element = artist[i];
      nameString += element.name + element.join_phrase + " ";
    }

    try {
      // const response = await axios.get(
      //   `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${q} ${nameString}&videoEmbeddable=true&type=video&key=AIzaSyAupeXohkZCNCUE2-ISAxEAacEvc4Eq8is`
      // );
      // console.log(response.items[0]["id"]["videoId"]);
      // setvideoId(response.items[0]["id"]["videoId"]);

      const response = await axios.get(
        `https://soundwavebe.avanzarsolution.com/api/yt/search?q=${q} ${nameString}`
      );
      console.log(response[0]["id"]);
      console.log(response);
      setvideoId(response[0]["id"]);
      playVideo(response[0]["id"]);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const BaseExample = useMemo(() => {
    return (
      <Grid
        data={recordingList}
        columns={[
          {
            id: "date_year",
            name: "date_year",
            hidden: true,
          },
          {
            id: "date_month",
            name: "date_month",
            hidden: true,
          },
          {
            id: "date_day",
            name: "date_day",
            hidden: true,
          },
          {
            id: "release_gid",
            name: "release_gid",
            hidden: true,
          },
          {
            id: "recording",
            name: "Title",
            formatter: (cell, row) =>
              _(
                <div>
                  <p
                    className="linkHover"
                    style={{ cursor: "pointer", margin: 0 }}
                    onClick={() =>
                      fetchYoutubeVideo(cell, row.cells[6]["data"])
                    }
                  >
                    {cell}
                  </p>
                </div>
              ),
            width: "auto",
          },
          {
            id: "length",
            name: "Length",
            formatter: (cell, row) =>
              _(
                <div>
                  <p style={{ cursor: "pointer", margin: 0 }}>
                    {convertMillisecondsToMinutes(recordingInfo.length)}
                  </p>
                </div>
              ),
            width: "auto",
          },
          {
            id: "track_artists",
            name: "Track artist",
            formatter: (cell, row) =>
              _(
                <div>
                  {cell.map((i, k) => (
                    <>
                      <span
                        className="linkHover"
                        style={{
                          margin: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => handleArtistNameClick(i.artist_gid)}
                      >
                        {i.name}{" "}
                      </span>
                      {i.join_phrase}{" "}
                    </>
                  ))}
                </div>
              ),
            width: "auto",
          },
          {
            id: "release_title",
            name: "Release title",
            formatter: (cell, row) =>
              _(
                <div>
                  <span
                    className="linkHover"
                    style={{
                      margin: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => handleReleaseClick(row.cells[3]["data"])}
                  >
                    {cell}
                  </span>
                </div>
              ),
            width: "auto",
          },
          {
            id: "release_artists",
            name: "Release artist",
            formatter: (cell, row) =>
              _(
                <div>
                  {cell.map((i, k) => (
                    <>
                      <span
                        className="linkHover"
                        style={{
                          margin: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => handleArtistNameClick(i.artist_gid)}
                      >
                        {i.name}{" "}
                      </span>
                      {i.join_phrase}{" "}
                    </>
                  ))}
                </div>
              ),
            width: "auto",
          },
          {
            id: "rg_primary_type",
            name: "Release type",
            width: "auto",
          },
          {
            id: "Country/Date",
            name: "Country/Date",
            formatter: (cell, row) =>
              _(
                <div>
                  <span>
                    {row.cells[0]["data"]}-{row.cells[1]["data"]}-
                    {row.cells[2]["data"]}{" "}
                  </span>
                </div>
              ),
            width: "auto",
          },
          {
            id: "label",
            name: "Label",
            width: "auto",
          },
          {
            id: "Catalog",
            name: "Catalog#",
            width: "auto",
          },
        ]}
        // search={true}
        // sort={true}
        // height={height}
        // style={{ height: { height }, overflowY: "scroll" }}
        pagination={{ enabled: true, limit: 25 }}
        autoWidth={true}
      />
    );
  }, [recordingList]);

  return (
    <>
      <Row>
        <Col md="2">
          <div className="leftMainDiv">
            <div>
              <p className="infoHeading">Recording information</p>
              <p className="infoText">
                <strong>Artist:</strong>{" "}
                {recordingInfo &&
                  recordingInfo.artist_credit_names &&
                  recordingInfo.artist_credit_names.map((i, k) => (
                    <>
                      <span
                        style={{
                          margin: 0,
                          color: "#5B7EF0",
                          cursor: "pointer",
                        }}
                        onClick={() => handleArtistNameClick(i.artist_gid)}
                      >
                        {i.name}{" "}
                      </span>
                      {i.join_phrase}{" "}
                    </>
                  ))}
              </p>
              <p className="infoText">
                <strong>Length:</strong>{" "}
                {convertMillisecondsToMinutes(recordingInfo.length)}
              </p>
              <p className="infoText">
                <strong>First release year:</strong>{" "}
                {recordingInfo.first_release_year}
              </p>
              <p className="infoText">
                <strong>ISRC:</strong> {recordingInfo.isrc}
              </p>
            </div>
            {/* <div>
              <p className="infoHeading">Rating</p>
              <StarRating rating={recordingInfo.rating} />
            </div> */}
            <div>
              <p className="infoHeading">Tags</p>
              {Object.entries(recordingTag).map(([groupName, items]) => (
                <div key={groupName} style={{ marginBottom: "10px" }}>
                  <strong>{groupName}</strong>
                  {items.map((item) => (
                    <p style={{ margin: 0 }} key={item.tag_id}>
                      {item.name}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col md="10" style={{ borderLeft: "1px solid #ccc" }}>
          <div className="nameDiv">
            <TbUserPentagon size={"40px"} color="#5B7EF0" />
            <div>
              <strong className="artistName">{recordingInfo.name}</strong>
              <p style={{ margin: 0 }}>
                Recording by ~
                {recordingInfo &&
                  recordingInfo.artist_credit_names &&
                  recordingInfo.artist_credit_names.map((i, k) => (
                    <>
                      <span
                        style={{
                          margin: 0,
                          color: "#5B7EF0",
                          cursor: "pointer",
                        }}
                        onClick={() => handleArtistNameClick(i.artist_gid)}
                      >
                        {i.name}{" "}
                      </span>
                      {i.join_phrase}{" "}
                    </>
                  ))}
              </p>
            </div>
          </div>
          <Tabs
            style={{ marginTop: "30px" }}
            defaultIndex={0}
            // onSelect={(index) => handleTabChange(index)}
          >
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Tags</Tab>
            </TabList>
            <TabPanel>
              <div>
                <p
                  style={{
                    color: "rgb(91, 126, 240)",
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Discography
                </p>
                {BaseExample}
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <p
                  style={{
                    color: "rgb(91, 126, 240)",
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Tags
                </p>
                {Object.entries(recordingTag).map(([groupName, items]) => (
                  <div key={groupName} style={{ marginBottom: "10px" }}>
                    <strong>{groupName}</strong>
                    {items.map((item) => (
                      <p style={{ margin: 0 }} key={item.tag_id}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Recording;
