import {
  GET_ARTIST,
  GET_ARTIST_SUCCESS,
  GET_ARTIST_FAIL,
  RESET_GET_ARTIST,
  GET_ARTIST_RELEASE,
  GET_ARTIST_RELEASE_SUCCESS,
  GET_ARTIST_RELEASE_FAIL,
  GET_ARTIST_RELEASE_GROUP,
  GET_ARTIST_RELEASE_GROUP_SUCCESS,
  GET_ARTIST_RELEASE_GROUP_FAIL,
  GET_ARTIST_URL,
  GET_ARTIST_URL_SUCCESS,
  GET_ARTIST_URL_FAIL,
  GET_ARTIST_TAG,
  GET_ARTIST_TAG_SUCCESS,
  GET_ARTIST_TAG_FAIL,
  GET_ARTIST_RELATIONSHIP,
  GET_ARTIST_RELATIONSHIP_SUCCESS,
  GET_ARTIST_RELATIONSHIP_FAIL,
  RESET_GET_ARTIST_RELEASE,
  RESET_GET_ARTIST_RELEASE_GROUP,
  RESET_GET_ARTIST_RELATIONSHIP,
  RESET_GET_ARTIST_TAG,
  RESET_GET_ARTIST_URL,
} from "./actionType";

const INIT_STATE = {
  artistData: [],
  artistCode: 0,

  artistReleaseData: [],
  artistReleaseCode: 0,
  artistReleaseDataCurrentPage: 1,
  artistReleaseDataTotalPage: 1,

  artistReleaseGroupData: [],
  artistReleaseGroupCode: 0,
  artistReleaseCurrentPage: 1,
  artistReleaseTotalPage: 1,

  artistTagData: [],
  artistTagCode: 0,

  artistRelationShipData: [],
  artistRelationShipCode: 0,

  artistUrlData: [],
  artistUrlCode: 0,
};

const artistReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ARTIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST:
          return {
            ...state,
            artistData: action.payload.data.data,
            artistCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //release
    case GET_ARTIST_RELEASE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELEASE:
          return {
            ...state,
            artistReleaseData: action.payload.data.data,
            artistReleaseCode: action.payload.data.code,
            artistReleaseDataCurrentPage: action.payload.data.current_page,
            artistReleaseDataTotalPage: action.payload.data.total_pages,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_RELEASE_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELEASE:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //release-group
    case GET_ARTIST_RELEASE_GROUP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELEASE_GROUP:
          return {
            ...state,
            artistReleaseGroupData: action.payload.data.data,
            artistReleaseGroupCode: action.payload.data.code,
            artistReleaseCurrentPage: action.payload.data.current_page,
            artistReleaseTotalPage: action.payload.data.total_pages,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_RELEASE_GROUP_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELEASE_GROUP:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //tag
    case GET_ARTIST_TAG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST_TAG:
          return {
            ...state,
            artistTagData: action.payload.data.data,
            artistTagCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_TAG_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST_TAG:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    //url
    case GET_ARTIST_URL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST_URL:
          return {
            ...state,
            artistUrlData: action.payload.data.data,
            artistUrlCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_URL_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST_URL:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case GET_ARTIST_RELATIONSHIP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELATIONSHIP:
          return {
            ...state,
            artistRelationShipData: action.payload.data.data,
            artistRelationShipCode: action.payload.data.code,
          };
        default:
          return { ...state };
      }
    case GET_ARTIST_RELATIONSHIP_FAIL:
      switch (action.payload.actionType) {
        case GET_ARTIST_RELATIONSHIP:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }

    case RESET_GET_ARTIST:
      return {
        ...state,
        artistData: [],
        artistCode: 0,
      };

    case RESET_GET_ARTIST_RELEASE:
      return {
        ...state,
        artistReleaseData: [],
        artistReleaseCode: 0,
      };

    case RESET_GET_ARTIST_RELEASE_GROUP:
      return {
        ...state,
        artistReleaseGroupData: [],
        artistReleaseGroupCode: 0,
      };

    case RESET_GET_ARTIST_RELATIONSHIP:
      return {
        ...state,
        artistRelationShipData: [],
        artistRelationShipCode: 0,
      };

    case RESET_GET_ARTIST_TAG:
      return {
        ...state,
        artistTagData: [],
        artistTagCode: 0,
      };

    case RESET_GET_ARTIST_URL:
      return {
        ...state,
        artistUrlData: [],
        artistUrlCode: 0,
      };

    default:
      return state;
  }
};

export default artistReducer;
