// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import "./App.css"; // New CSS file for layout styling
import SearchArtist from "./Pages/SearchPages/SearchArtist";
import SearchRelease from "./Pages/SearchPages/SearchRelease";
import SearchReleaseGroup from "./Pages/SearchPages/SearchReleaseGroup";
import Artist from "./Pages/ArtistPages/Artist";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tabs/style/react-tabs.css";
import ReleaseGroup from "./Pages/ReleasePages/ReleaseGroup";
import Release from "./Pages/ReleasePages/Release";
import Recording from "./Pages/Recording/Recording";
import SearchRecording from "./Pages/SearchPages/SearchRecording";
import AboutUs from "./Pages/Company/AboutUs";
import ContactUs from "./Pages/Company/ContactUs";
import Help from "./Pages/Company/Help";
import PrivacyPolicy from "./Pages/Company/PrivacyPolicy";
import Login from "./Pages/Login/Login";
import DraggableYouTubePlayer from "./Component/Yt/DraggableYouTubePlayer";
import { PlayerProvider, usePlayer } from "./Component/Yt/PlayerContext";

function App() {
  // useEffect(() => {
  //   // Disable right-click context menu
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   // Attach the event listener to the document
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  const navLinks = [
    { label: "Home", path: "/" },
    { label: "Contact", path: "/contact" },
  ];

  const PlayerWrapper = () => {
    const { videoId, isMinimized, isDraggable, closePlayer } = usePlayer();

    return (
      <DraggableYouTubePlayer
        videoId={videoId}
        isMinimized={isMinimized}
        isDraggable={isDraggable}
        onClose={closePlayer}
      />
    );
  };

  return (
    <PlayerProvider>
      <Router>
        <PlayerWrapper />
        <div
          className="app-container"
          // style={{ userSelect: "none" }}
        >
          <Header links={navLinks} />
          <main className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/searchArtist/:searchtype/:searchquery"
                element={<SearchArtist />}
              />
              <Route
                path="/searchRelease/:searchtype/:searchquery"
                element={<SearchRelease />}
              />
              <Route
                path="/searchReleaseGroup/:searchtype/:searchquery"
                element={<SearchReleaseGroup />}
              />
              <Route
                path="/searchRecording/:searchtype/:searchquery"
                element={<SearchRecording />}
              />
              <Route path="/artist/:gid" element={<Artist />} />
              <Route path="/releaseGroup/:gid" element={<ReleaseGroup />} />
              <Route path="/release/:gid" element={<Release />} />
              <Route path="/recording/:gid" element={<Recording />} />

              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/help" element={<Help />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </PlayerProvider>
  );
}

export default App;
